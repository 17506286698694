import Keycloak from 'keycloak-js';

export function getClientId() {
  const enterprise = 'https://login.innovationwithin.services/realms/discovery';
  const production = 'production';
  const ci = 'ci';
  const dev = ci; //'dev';

  if (typeof window !== "undefined") {
    if (window.location.host === 'micron.app.innovationwithin.services') {
      return enterprise;
    }

    if (window.location.host === 'app.innovationwithin.services') {
      return production;
    }

    if (window.location.host === 'ci.innovationwithin.services') {
      return ci;
    }
  }

  return dev;
}

export function generateKeycloakConfiguration() {
  return {
    url: 'https://login.innovationwithin.services',
    realm: 'discovery',
    clientId: getClientId()
  }
}

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

let keycloak = () => { };

if (typeof window !== "undefined") {
  keycloak = new Keycloak(generateKeycloakConfiguration());
}

export default keycloak;
